<template>
    <v-app-bar
        height="60"
        app
        flat
        class="gst-header-with-count-down">
        <div class="d-flex align-center mr-5">
            <TenantLogo class="gst-header-with-count-down__logo" />
        </div>
        <v-spacer />
        <template v-if="$vuetify.breakpoint.mdAndUp">
            <LoginButtonVariant1 v-if="!userIsAuth" :data-test-id="$testId('login')" @click="goToLogin" />
            <UserDropdownMenu v-if="userIsAuth && userDetail" :user-detail="userDetail" />
        </template>
        <template v-else>
            <LoginButtonVariant1 v-if="!userIsAuth" :data-test-id="$testId('login')" @click="goToLogin" />
            <UserMobileMenu v-if="userIsAuth && userDetail"
                :user-detail="userDetail"
                @expand="userMenuExpanded=true"
                @collapse="userMenuExpanded=false" />
        </template>
        <template v-slot:extension>
            <v-flex
                d-flex
                align-center
                flex-grow-0
                class="gst-header-with-count-down__count-down tertiary-text mx-auto"
                :class="{
                    'gst-header-with-count-down__count-down--is-on-before-finish-threshold': isReachedBeforeFinishThreshold
                }">
                <span>{{ $t('labels.countDown' ) }}</span>
                <v-flex
                    d-flex
                    flex-row
                    align-center
                    shrink
                    class="ml-2 ml-md-3 u-alpha-background">
                    <BaseIcon symbol-id="icons--clock_full" class="mr-1" />
                    <CountDown
                        v-if="dateTimeTill"
                        :date-time-till="dateTimeTill"
                        format="mm:ss"
                        @finish="$emit( 'finish' )"
                        @progress="onProgressDo" />
                </v-flex>
            </v-flex>
        </template>
    </v-app-bar>
</template>
<script>
    import { mapGetters } from 'vuex';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import CountDown from '@core/shared/components/misc/CountDown.vue';
    import LoginButtonVariant1 from '@core/shared/components/authentication/LoginButtonVariant1.vue';
    import { toggleBodyScroll as documentUtilsToggleBodyScroll } from '@core/utils/documentUtils';
    import UserDropdownMenu from '@core/shared/components/menu/UserDropdownMenu';
    import UserMobileMenu from '@core/shared/components/menu/UserMobileMenu';

    export default {
        name: 'HeaderWithCountDown',
        components: {
            BaseIcon,
            CountDown,
            LoginButtonVariant1,
            UserDropdownMenu,
            UserMobileMenu
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckoutWizard._components.headerWithCountDown'
        },
        props: {
            dateTimeTill: {
                type: Date,
                default: ( ) => { return new Date( ); }
            }
        },
        data: () => ( {
            userMenuExpanded: false,
            countDownSecondsRemaining: 0,
            beforeFinishThresholdSeconds: 2 * 60
        } ),
        computed: {
            ...mapGetters( {
                userIsAuth: 'user/isAuth',
                userDetail: 'user/wallet/getDetail'
            } ),
            isReachedBeforeFinishThreshold( ) {
                return this.countDownSecondsRemaining <= this.beforeFinishThresholdSeconds;
            }
        },
        watch: {
            userMenuExpanded( val ) {
                documentUtilsToggleBodyScroll( val );
            }
        },
        methods: {
            goToLogin() {
                this.$router.push( { name: 'auth.login', params: { method: 'redirect' } } );
            },
            onProgressDo( value ) {
                this.countDownSecondsRemaining = value;
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-header-with-count-down {
        background-color: theme-color( 'transparent' ) !important;

        .gst-header-with-count-down__count-down {
            position: relative;
            padding: theme-spacing( 3, 4 );
            background: theme-color( 'white' );
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxs' );
            font-weight: font-weight( 'regular' );
            border-width: 0 1px 1px;
            border-style: solid;
            border-color: theme-color( 'primary-lighten-2' );
            border-bottom-right-radius: border-radius( 'm' );
            border-bottom-left-radius: border-radius( 'm' );

            span {
                white-space: nowrap;
            }

            div {
                position: relative;
                height: 24px;
                line-height: line-height( 'xxl' );
                font-size: font-size( 'l' );
                font-weight: font-weight( 'medium' );

                svg {
                    ::v-deep .gst-svg-icon {
                        fill: theme-color( 'primary' );
                    }
                }
            }
        }

        .gst-header-with-count-down__count-down::after {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: theme-color( 'background-timer' );
            content: "";
            opacity: 0.1;
            border-bottom-right-radius: border-radius( 'm' );
            border-bottom-left-radius: border-radius( 'm' );
        }

        .gst-header-with-count-down__count-down--is-on-before-finish-threshold {
            border-color: theme-color( 'error' );

            div {
                svg {
                    ::v-deep .gst-svg-icon {
                        fill: theme-color( 'error' );
                    }
                }
            }
        }

        .gst-header-with-count-down__count-down--is-on-before-finish-threshold::after {
            background: theme-color( 'error' );
        }

        @include mobile-only {
            .gst-header-with-count-down__count-down {
                padding: theme-spacing( 3, 2 );
            }
        }
    }
</style>

<style lang="scss">
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-checkout header.gst-header-with-count-down {
        @include mobile-only {
            width: 100vw;
        }
    }

    .gst-header-with-count-down {
        .v-toolbar__content {
            background: theme-color( 'white' ) !important;
            border-bottom: 1px solid theme-color-hex( 'quinary' ) !important;
        }

        .v-toolbar__extension {
            padding: theme-spacing( 0 ) !important;
            background-color: theme-color( 'transparent' ) !important;
        }

        .gst-header-with-count-down__logo {
            svg {
                @include mobile-only {
                    max-width: 158px;
                }
            }
        }
    }
</style>
