<template>
    <div class="gst-payment-widget">
        <DataLoadingOverlay
            :show="loading"
            color="white"
            opacity="1">
            <div :ref="containerId" class="container px-0 py-0">
                <form ref="cardForm" action="" method="POST">
                    <div class="block-group d-flex flex-column">
                        <ProcessOutInput
                            :is-focused="cardNumber.isFocused"
                            :is-valid="cardNumber.isValid"
                            :label=" $t('labels.cardNumber') "
                            data-process-out-input="cc-number"
                            placeholder="4242424242424242"
                            class="d-flex flex-column">
                            <p v-if="!cardNumber.isValid && cardNumber.isValid !== null" slot="error" class="input-error">
                                {{ $t('errors.cardNumber') }}
                            </p>
                            <CardNumberIcon class="mr-2" />
                        </ProcessOutInput>

                        <div class="gst-payment-widget__expiration-date-cvc-section d-flex flex-md-row flex-column align-start mt-2">
                            <div class="d-flex flex-row align-start">
                                <ProcessOutInput
                                    class="mr-4"
                                    :is-focused="expirationDate.isFocused"
                                    :is-valid="expirationDate.isValid"
                                    :label=" $t('labels.expirationDate') "
                                    data-process-out-input="cc-exp"
                                    placeholder="MM / YY">
                                    <p v-if="!expirationDate.isValid && expirationDate.isValid !== null" slot="error" class="input-error">
                                        {{ $t('errors.expirationDate') }}
                                    </p>
                                </ProcessOutInput>
                                <ProcessOutInput
                                    :is-focused="cvc.isFocused"
                                    :is-valid="cvc.isValid"
                                    :label=" $t('labels.securityCode') "
                                    data-process-out-input="cc-cvc"
                                    placeholder="CVC">
                                    <p v-if="!cvc.isValid && cvc.isValid !== null" slot="error" class="input-error">
                                        {{ $t('errors.securityCode') }}
                                    </p>
                                </ProcessOutInput>
                            </div>
                            <div class="gst-payment-widget__cvc-icon d-flex align-center mt-md-5 mt-0 ml-md-2">
                                <CvcIcon />
                                <div class="gst-payment-widget__cvc-message">
                                    {{ $t('messages.securityCode') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </DataLoadingOverlay>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import apiPaymentClientConstants from '@core/utils/constants/apiPaymentClient';
    import apiPaymentClient from '@core/api/apiPaymentClient';
    import DataLoadingOverlay from '@core/shared/components/loading/DataLoadingOverlay.vue';
    import ProcessOutInput from '@core/shared/components/payment/PaymentWidgetProcessoutInput.vue';
    import CvcIcon from '@core/shared/components/payment/icons/cvc.svg';
    import CardNumberIcon from '@core/shared/components/payment/icons/card-number.svg';

    export default {
        name: 'PaymentWidgetProcessOut',
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.payments.paymentWidget.paymentWidgetProcessOut'
        },
        components: {
            CvcIcon,
            CardNumberIcon,
            ProcessOutInput,
            DataLoadingOverlay
        },
        emits: [
            'update-payment-fields-validity',
            'open-authorization',
            'close-authorization',
            'load-client-start',
            'load-client-failed',
            'load-client-success'
        ],
        props: {
            cartId: {
                type: String,
                required: true,
                default: '6d4601d8-55bb-4986-80df-a9143cb752f4'
            },
            token: {
                type: String,
                required: true
            },
            customerModel: {
                type: Object,
                required: true
            },
            paymentModel: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                loading: true,
                errorLoading: false,
                client: null,
                form: null,
                debounceTimer: null,
                containerId: null,
                cardNumber:{
                    isValid: null,
                    isFocused: false,
                    error: null
                },
                cvc:{
                    isValid: null,
                    isFocused: false,
                    error: null
                },
                expirationDate: {
                    isValid: null,
                    isFocused: false,
                    error: null
                },
                openedAuthorizationModal: false
            };
        },
        computed: {
            getProcessoutPayload( ) {
                const { firstName, lastName, email, address, city, country } = this.customerModel ?? {};
                return {
                    firstName: firstName ?? null,
                    lastName: lastName ?? null,
                    email: email ?? null,
                    address: address ?? null,
                    city: city ?? null,
                    countryCode: country ?? null,
                    amount: this.paymentModel.amount ?? null
                };
            },
            isFormValid() {
                const fields = [ this.cardNumber, this.expirationDate, this.cvc ];
                const areInvalids = ( element ) => element.isValid !== true;
                const validity = !fields.some( areInvalids );
                return validity;
            }
        },
        watch: {
            'isFormValid': {
                handler: function ( value, oldValue ) {
                    if ( value !== oldValue ) {
                        this.onCardValidityChangeDo( );
                    }
                },
                immediate: true
            }
        },
        methods: {
            ...mapActions( {
                createInvoice: 'cart/createInvoice',
            } ),
            onCardValidityChangeDo( ) {
                this.$emit( 'update-payment-fields-validity', this.isFormValid );
            },
            debounce( callback, time )  {
                window.clearTimeout( this.debounceTimer );
                this.debounceTimer = window.setTimeout( callback, time );
            },
            validateCardNumber( form ) {
                form.getNumberField().validate(
                    ( ) =>  {
                        this.debounce( () => {
                            this.cardNumber.isValid = true;
                        }, 200 );
                    },
                    ( ) => {
                        this.cardNumber.isValid = false;
                    } );
            },
            validateExpirationDate( form ){
                form.getExpiryField().validate(
                    ( ) =>  {
                        this.debounce( () => {
                            this.expirationDate.isValid = true;
                        }, 200 );
                    },
                    ( ) => {
                        this.expirationDate.isValid = false;
                    } );
            },
            validateCVC( form )  {
                form.getCVCField().validate(
                    ( ) =>  {
                        this.debounce( () => {
                            this.cvc.isValid = true;
                        }, 200 );
                    },
                    ( ) => {
                        this.cvc.isValid = false;
                    } );
            },
            addFieldsListeners( form ) {
                form.getNumberField().on( 'focus', ( ) => {
                    this.cardNumber.isFocused = true;
                } );

                form.getNumberField().on( 'focusout', ( ) => {
                    this.cardNumber.isFocused = false;
                    this.validateCardNumber( form );
                } );

                form.getExpiryField().on( 'focus', ( ) => {
                    this.expirationDate.isFocused = true;

                } );

                form.getExpiryField().on( 'focusout', ( ) => {
                    this.validateExpirationDate( form );
                    this.expirationDate.isFocused = false;
                } );

                form.getCVCField().on( 'focus', ( ) => {
                    this.cvc.isFocused = true;
                } );

                form.getCVCField().on( 'focusout', ( ) => {
                    this.validateCVC( form );
                    this.cvc.isFocused = false;
                } );

                form.getNumberField().on( 'keydown', ( ) => {
                    this.validateCardNumber( form, 'keydown' );
                } );

                form.getExpiryField().on( 'keydown', ( ) => {
                    this.validateExpirationDate( form, 'keydown' );
                } );

                form.getCVCField().on( 'keydown', ( ) => {
                    this.validateCVC( form, 'keydown' );
                } );

                form.addEventListener( 'submit', ( e ) => {
                    e.preventDefault( );
                } );
            },
            /**
             * @description Submit the form
             * @param {String} invoiceId
             * @returns {Promise<void>}
             */
            async submit( ) {
                return new Promise( ( resolve ) => {
                    if ( this.paymentModel.amount !== 0 ) {
                        this.createInvoice( { id: this.cartId, data: this.getProcessoutPayload } )
                            .then( ( { success, data } ) => {
                                if ( !success ) {
                                    resolve( { success: false } );
                                }
                                this.onCardValidityChangeDo();
                                this.client.tokenize(
                                    this.form,
                                    {},
                                    ( token ) => {
                                        this.openedAuthorizationModal = true;

                                        this.$emit( 'open-authorization' );
                                        this.client.makeCardPayment(
                                            data.id,
                                            token,
                                            {
                                                authorize_only: true
                                            },
                                            ( iv ) => {
                                                this.$emit( 'close-authorization' );
                                                resolve(
                                                    {
                                                        success: true,
                                                        type: apiPaymentClientConstants.PROVIDERS.PROCESSOUT,
                                                        data: {
                                                            token,
                                                            invoiceId: iv,
                                                        }
                                                    }
                                                );
                                            },
                                            ( err ) => {
                                                this.$emit( 'close-authorization' );
                                                resolve( { success: false, message: err.message } );
                                            }
                                        );
                                    },
                                    ( err ) => {
                                        resolve( { success: false, message: err.message } );
                                    } );
                            } )
                            .catch( () => { resolve( { success: false } ); } );
                    } else 
                    resolve( { success: true } );
                } );
            },
            showErrors( ) {
                this.validateCardNumber( this.form );
                this.validateExpirationDate( this.form );
                this.validateCVC( this.form );
            },
        },
        async created( ) {
            this.loading = true;
            this.$emit( 'load-client-start' );

            if ( typeof ProcessOut === 'undefined' && !await apiPaymentClient.loadClient( apiPaymentClientConstants.CONFIG.PROCESSOUT ) ) {
                this.$emit( 'load-client-failed' );
                this.errorLoading = false;
                this.loading = false;
                return;
            }

            this.containerId = new Date( ).getTime( );
            this.$nextTick( ( ) => {
                this.client = new ProcessOut.ProcessOut( this.token );
                this.client.setupForm(
                    this.$refs.cardForm,
                    {
                        style: {
                            fontSize: '14px',
                            '::placeholder': {
                                color: '#ECEFF1',
                            },
                        },
                        requireCVC: true
                    },
                    ( form ) => {
                        this.loading = false;
                        this.form = form;
                        this.addFieldsListeners( form );
                        this.$emit( 'load-client-success' );
                    },
                    ( err ) => {
                        this.$emit( 'load-client-failed', err );
                        this.errorLoading = true;
                        this.loading = false;
                    }
                );
                this.$emit( 'update-payment-fields-validity', false );
            } );
        },
        destroyed( ) {
            /**
             * we need to hide the iframe
             * TODO find a better solution that this one
             */
            if ( this.openedAuthorizationModal ) {
                const iFrameWrapperAuthorization = document.getElementById( 'processoutjs-action-modal' );

                if ( iFrameWrapperAuthorization ) {
                    iFrameWrapperAuthorization.style.display = 'none';
                }
            }
        }
    };
</script>

<style lang="scss">

@import "@scssMixins";
@import "@scssVariables";

.gst-payment-widget {
    .gst-payment-widget__expiration-date-cvc-section {
        max-width: 560px !important;

        div:nth-child(1) {
            max-width: 348px !important;

            div {
                width: 100% !important;
            }
        }

        .gst-payment-widget__cvc-message {
            line-height: 1.2;
            padding-left: 5px;
            color: theme-color( 'septenary' );
            font-size: 12px !important;
        }
    }

    .input-error {
        line-height: 1.2;
        color: #CA2A2A !important;
        font-size: font-size( 'xxs' ) !important;
    }

    .block-group {
        *zoom: 1;

        .gst-processout-input__container {
            position: relative;
            width: 100%;
            line-height: 8px;

            p {
                color: theme-color( 'quaternary' );
                font-size: font-size( 's' );
                font-weight: font-weight( 'regular' );
            }
        }
    }

    html,
    body {
        background: #ECEFF1;
        font-size: 16px;
    }

    [data-processout-input],
    input {
        width: 100%;
        padding: 8px;
        border: 1px solid #BFBFBF;
        margin-bottom: 20px;
        font-size: 14px;
        border-radius: 4px;
        box-shadow: 0 5px 7px rgba( 50, 50, 93, 0.04 ), 0 1px 3px rgba( 0, 0, 0, 0.03 );

        iframe {
            width: 84% !important;
        }
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: #ECEFF1;
    }

    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #ECEFF1;
        opacity: 0 !important;
    }

    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: #ECEFF1;
        opacity: 1;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #ECEFF1;
    }

    [data-processout-input]:nth-child(1) {
        display: inline-block;
        width: 48%;
    }

    [data-processout-input]:nth-child(2) {
        display: inline-block;
        padding: 8px;
        border: 1px solid #BFBFBF;
        border-radius: 2px !important;
    }

    #success {
        color: #4CAF50;
    }

    #processoutjs-action-modal {
        @include viewport-height( 100, !important );

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    @include mobile-only {
        .gst-payment-widget__cvc-icon {
            display: flex;
            width: 100% !important;
            max-width: 348px;
            justify-content: flex-end;
        }
    }
}
</style>
