<template>
    <div class="gst-payment-provider" :data-has-error="hasError">
        <DataLoadingOverlay
            class="gst-payment-provider__loading-overlay"
            :show="isLoading"
            color="white"
            opacity="1">
            <div :id="paymentClientContainerId" :key="token"></div>
        </DataLoadingOverlay>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import apiPaymentClientConstants from '@core/utils/constants/apiPaymentClient';
    import paymentStyleOverrides from '@core/utils/constants/paymentStylesOverrides';
    import apiPaymentClient from '@core/api/apiPaymentClient';
    import DataLoadingOverlay from '@core/shared/components/loading/DataLoadingOverlay.vue';
    import { LogService } from '@core/services';

    export default {
        name: 'PaymentWidgetBraintree',
        components: {
            DataLoadingOverlay
        },
        props: {
            token: {
                type: String,
                required: true
            },
            paymentClientContainerId: {
                type: String,
                default: 'paymentClientContainer'
            }
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.payments.paymentWidget'
        },
        data() {
            return {
                isLoading: true,
                hasError: true
            };
        },
        emits: [
            'load-client-start',
            'load-client-success',
            'load-client-failed',
            'update-payment-fields-validity',
        ],
        computed: {
            ...mapState( {
                language: state => state.appState.language,
            } ),
            paymentClientContainerConfig( ) {
                const { language } = this;

                return {
                    locale: language,
                    vaultManager: false,
                    translations: {
                        payWithCard: 'Pay with your PC MasterCard!',

                    },
                    card: {
                        overrides: paymentStyleOverrides.OVERRIDES_STYLES,
                        cardholderName: {
                            required: true
                        }
                    }
                };
            }
        },
        methods: {
            async openPaymentIframe() {
                const { token, paymentClientContainerConfig } = this;
                this.$emit( 'load-client-start', true );
                if ( this._instancePaymentClient ) {
                    this._instancePaymentClient.off( 'card:validityChange', this.onCardValidityChangeDo );
                }

                return new Promise ( ( resolve ) => {
                    this.isWidgetLoaded = false;
                    apiPaymentClient.openPaymentIFrame(
                        token,
                        `#${this.paymentClientContainerId}`,
                        paymentClientContainerConfig,
                        ( createError, instancePaymentClient ) => {
                            this.isLoading = false;

                            if ( createError ) {
                                LogService.error( createError._braintreeWebError.message );
                                this.$emit( 'load-client-failed', this.isWidgetLoaded );
                                resolve( false );
                                return;
                            }
                            this._instancePaymentClient = instancePaymentClient;
                            instancePaymentClient.on( 'card:validityChange', this.onCardValidityChangeDo );
                            this.isWidgetLoaded = true;

                            this.$emit( 'load-client-success', this.isWidgetLoaded );

                            resolve( true );
                        }
                    );
                } );

            },
            updateHintText( ) {
                const styleElement = document.createElement( 'style' );
                styleElement.innerHTML = `
                .braintree-form__field-group[data-braintree-id="cvv-field-group"].braintree-form__field-group.braintree-form__field-group{
                    .braintree-form__field::before {
                        content: '${this.$t( 'messages.securityCode' )}';
                    }
                }
                `;
                document.head.appendChild( styleElement );
            },
            onCardValidityChangeDo( event ) {
                const validPaymentFields = Object.values( event.fields ).every( field => field.isValid );

                this.hasError = !validPaymentFields;
                this.$emit( 'update-payment-fields-validity', validPaymentFields );
            },
            /**
             * @description Submit the form
             * @returns {Promise<void>}
             */
            submit() {
                return new Promise( ( resolve ) => {
                    const getCardInfo = ( payload ) => {
                        return payload ? {
                            'token': payload.nonce,
                            'expireMonth': parseInt( payload.details.expirationMonth ),
                            'expireYear': parseInt( payload.details.expirationYear ),
                            'type': payload.details.cardType,
                            'lastFour': payload.details.lastFour,
                        } : null;
                    };

                    this._instancePaymentClient.requestPaymentMethod ( ( error, payload ) => {
                        if ( error ) {
                            resolve ( { success: false } );
                        } else {
                            resolve ( {
                                success: true,
                                type: apiPaymentClientConstants.PROVIDERS.BRAINTREE,
                                data: getCardInfo( payload ) },
                            );
                        }

                    } );
                } );
            },
            showErrors( ) {
                return this.submit( );
            },
        },
        mounted( ) {
            this.updateHintText();
        },
        async created( ) {
            if ( typeof braintree === 'undefined' &&  !await apiPaymentClient.loadClient( apiPaymentClientConstants.CONFIG.BRAINTREE ) ) {
                this.$emit( 'load-client-failed' );
                this.errorLoading = false;
                this.loading = false;
                return;
            }
            this.$watch(
                'token',
                async ( value, oldValue ) => {
                    if ( value && value !== oldValue ) {
                        await this.openPaymentIframe( );
                    }
                },
                {
                    immediate: true
                }
            );
            this.$emit( 'update-payment-fields-validity', false );
            this._instancePaymentClient = null;
        },
        destroyed( ) {
            if ( this._instancePaymentClient ) {
                this._instancePaymentClient.off( 'card:validityChange', this.onCardValidityChangeDo );
            }
        }
    };
</script>

<style lang="scss" scoped>
    .gst-payment-provider,
    .gst-payment-provider__loading-overlay {
        padding: 0;
        min-height: 100px;
    }
</style>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .braintree-sheet {
        border: none;
    }

    .braintree-sheet__header {
        display: none;
    }

    .braintree-methods {
        display: none !important;
    }

    .braintree-placeholder {
        display: none;
    }

    .braintree-sheet__content--form {
        padding: theme-spacing( 0 );
    }

    .braintree-form__field-group {
        padding: 5px 10px !important;
        border-style: solid;
        border-width: 1px;
        border-color: theme-color( 'quinary' );
        border-radius: border-radius( 'xxs' );

        .braintree-form__label {
            color: theme-color( 'primary' ) !important;
        }
    }

    @media (min-width: 468px) {
        .braintree-form__field-group[data-braintree-id="expiration-date-field-group"] {
            margin-right: 5px !important;
        }
    }

    .braintree-form__hosted-field {
        padding-top: theme-spacing( 0 ) !important;
        padding-left: theme-spacing( 0 ) !important;
        border: none !important;
        margin: theme-spacing( 0 ) !important;
    }

    .gst-checkwiz-step-payment__total {
        width: 100%;
        text-align: center;
    }
</style>
