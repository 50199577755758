import { render, staticRenderFns } from "./Step2DeliveryForm.vue?vue&type=template&id=338e53ab&scoped=true&"
import script from "./Step2DeliveryForm.vue?vue&type=script&lang=js&"
export * from "./Step2DeliveryForm.vue?vue&type=script&lang=js&"
import style0 from "./Step2DeliveryForm.vue?vue&type=style&index=0&id=338e53ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "338e53ab",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VCol,VContainer,VRow,VSelect,VTextField})
