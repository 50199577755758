<template>
    <v-container class="gst-checkout-step-2-form py-0 px-2">
        <v-row>
            <v-col cols="12" class="pt-0 pb-2">
                <SectionTitleSmall>{{ $t("fieldsets.customerInformation") }}</SectionTitleSmall>
            </v-col>
            <v-col xs="6" class="py-0 px-1">
                <v-text-field
                    v-model="customerModel.firstName"
                    :data-test-id="$testId('input.firstName')"
                    color="primary"
                    :label="$t('labels.firstName')"
                    :error-messages="_extractValidationsMessages( 'customerModel.firstName' )"
                    required
                    class="gst-input--secondary gst-input"
                    outlined
                    maxlength="30" />
            </v-col>
            <v-col xs="6" class="py-0 px-1">
                <v-text-field
                    v-model="customerModel.lastName"
                    :data-test-id="$testId('input.lastName')"
                    color="primary"
                    :label="$t('labels.lastName')"
                    :error-messages="_extractValidationsMessages( 'customerModel.lastName' )"
                    required
                    class="gst-input--secondary gst-input"
                    outlined
                    maxlength="30" />
            </v-col>
            <v-col cols="12" class="py-0 px-1">
                <v-text-field
                    v-model="customerModel.address"
                    :data-test-id="$testId('input.address')"
                    color="primary"
                    :label="$t('labels.address')"
                    :error-messages="_extractValidationsMessages( 'customerModel.address' )"
                    required
                    class="gst-input--secondary gst-input"
                    outlined
                    maxlength="100" />
            </v-col>
            <v-col cols="6" class="py-0 px-1">
                <v-select
                    ref="countries"
                    v-model="country"
                    :data-test-id="$testId('select.country')"
                    :items="countries"
                    item-value="code"
                    item-text="name"
                    :label="$t('labels.country')"
                    color="primary"
                    outlined
                    return-object
                    :error-messages="_extractValidationsMessages( 'customerModel.country' )"
                    class="gst-input--secondary gst-input"
                    :class="{
                        'gst-input--empty-value-selected': !customerModel.country,
                    }"
                    @changes="onChangeCountryDo"
                    @blur="onBlurCountryDo">
                    <template slot="append">
                        <i class="gst-icon-svg">
                            <BaseIcon symbol-id="icons--dropdown_arrow" class="gst-dropdown-icon-svg" />
                        </i>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="6" class="py-0 px-1">
                <DataLoadingOverlay
                    :show="isLoadingStates"
                    :color="'white'"
                    :loader-props="{ size: 22 }"
                    :loader-classes="$v.customerModel.state.$error ? 'mt-n6' : 'mt-n3'">
                    <v-select
                        ref="states"
                        v-model="state"
                        :data-test-id="$testId('select.state')"
                        :items="states"
                        item-value="code"
                        item-text="name"
                        :label="$t('labels.state')"
                        color="primary"
                        return-object
                        outlined
                        :error-messages="_extractValidationsMessages( 'customerModel.state' )"
                        :disabled="!isLoadingStates && !states.length"
                        class="gst-input--secondary gst-input"
                        :class="{
                            'gst-input--without-value': !states.length,
                            'gst-input--empty-value-selected': !customerModel.state,
                        }">
                        <template slot="append">
                            <i class="gst-icon-svg">
                                <BaseIcon symbol-id="icons--dropdown_arrow" class="gst-dropdown-icon-svg" />
                            </i>
                        </template>
                    </v-select>
                </DataLoadingOverlay>
            </v-col>
            <v-col cols="6" class="py-0 px-1">
                <v-text-field
                    v-model="customerModel.city"
                    :data-test-id="$testId('input.city')"
                    color="primary"
                    :label="$t('labels.city')"
                    :error-messages="_extractValidationsMessages( 'customerModel.city' )"
                    required
                    class="gst-input--secondary gst-input"
                    outlined
                    maxlength="100" />
            </v-col>
            <v-col cols="6" class="py-0 px-1">
                <v-text-field
                    v-model="customerModel.zip"
                    :data-test-id="$testId('input.zip')"
                    color="primary"
                    :label="$t('labels.zip')"
                    :error-messages="_extractValidationsMessages( 'customerModel.zip' )"
                    required
                    class="gst-input--secondary gst-input"
                    outlined />
            </v-col>
            <v-col v-if="isPhoneRequired" :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" class="py-0 px-1">
                <v-text-field
                    v-model="customerModel.phone"
                    type="tel"
                    :data-test-id="$testId('input.phone')"
                    color="primary"
                    class="gst-input--secondary gst-input"
                    :label="$t('labels.phone')"
                    :error-messages="_extractValidationsMessages( 'customerModel.phone' )"
                    required
                    outlined />
            </v-col>
            <v-col :cols="isPhoneRequired && $vuetify.breakpoint.mdAndUp ? 6 : 12" class="py-0 px-1">
                <v-text-field
                    v-model="customerModel.email"
                    :data-test-id="$testId('input.email')"
                    color="primary"
                    class="gst-input--secondary gst-input"
                    :label="$t('labels.email')"
                    :error-messages="_extractValidationsMessages( 'customerModel.email' )"
                    required
                    outlined
                    maxlength="255"
                    @input="customerModel.email = customerModel.email.toLowerCase()" />
            </v-col>
            <v-col v-if="!isLoyaltyTypeNone" cols="12" class="py-0 px-1">
                <Step2DeliveryFormMemberId
                    v-model="customerModel.memberId"
                    :label="$t('labels.loyalty')"
                    :test-id="$testId('input.loyalty')"
                    :disabled="isDisabledMemberId"
                    :errors="_extractValidationsMessages( 'customerModel.memberId' )" />
            </v-col>
            <v-col v-if="hasShippingOptions" cols="12" class="pa-0">
                <v-col cols="12" class="pt-0 pb-2">
                    <SectionTitleSmall>{{ $t("fieldsets.deliveryInformation") }}</SectionTitleSmall>
                </v-col>
                <v-col cols="12" class="gst-info pt-0 px-1">
                    <BaseIcon symbol-id="icons--warning" class="d-inline-block u-align-vertical-middle" />
                    <span class="d-inline-block u-align-vertical-middle tertiary--text">{{ $t("messages.info") }}</span>
                </v-col>
            </v-col>
            <v-col cols="12"
                sm="8"
                md="8"
                class="py-0 px-1">
                <DataLoadingOverlay
                    :show="shippingModel.loading"
                    :color="'white'">
                    <Step2DeliveryFormShippingOptions
                        :shipping-model="shippingModel"
                        :error-messages="_extractValidationsMessages( 'shippingModel.optionId' )" />
                </DataLoadingOverlay>
            </v-col>
            <v-col cols="12" class="py-0 px-1">
                <v-checkbox
                    v-model="agreeModel.terms"
                    off-icon="$vuetify.icons.checkboxUnchecked"
                    on-icon="$vuetify.icons.checkboxChecked"
                    :data-test-id="$testId('checkbox.terms')"
                    class="gst-checkbox gst-checkout-step-2-form__field-terms-conditions d-inline-block u-align-vertical-middle"
                    :class="{ 'mt-0': !hasShippingOptions }"
                    :error-messages="_extractValidationsMessages( 'agreeModel.terms' )">
                    <template slot="label">
                        <Step2DeliveryFormTermsLabel />
                    </template>
                </v-checkbox>
            </v-col>
            <v-col v-if="hasHealthCheck" cols="12" class="py-0 px-1">
                <v-checkbox
                    v-model="agreeModel.healthCheck"
                    off-icon="$vuetify.icons.checkboxUnchecked"
                    on-icon="$vuetify.icons.checkboxChecked"
                    :data-test-id="$testId('checkbox.healthCheck')"
                    class="gst-checkbox gst-checkout-step-2-form__field-healthcheck d-inline-block u-align-vertical-middle mt-0"
                    :error-messages="_extractValidationsMessages( 'agreeModel.healthCheck' )">
                    <template slot="label">
                        <span>
                            {{ $t("labels.healthCheck") }}
                        </span>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex';
    import {
        zipFormat as formatUtilsZipFormat
    } from '@core/utils/formatUtils';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import DataLoadingOverlay from '@core/shared/components/loading/DataLoadingOverlay.vue';
    import SectionTitleSmall from '@core/shared/components/misc/SectionTitleSmall.vue';
    import Step2DeliveryFormValidationMixin from './Step2DeliveryFormValidationMixin.js';
    import Step2DeliveryFormTermsLabel from './Step2DeliveryFormTermsLabel';
    import Step2DeliveryFormShippingOptions from './Step2DeliveryFormShippingOptions.vue';
    import Step2DeliveryFormMemberId from './Step2DeliveryFormMemberId.vue';

    export default {
        name: 'Step2DeliveryForm',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckoutWizard._components.step2Delivery.form'
        },
        testIdOptions: {
            keyPrefix: 'checkoutWizard.step2Delivery.form'
        },
        components: {
            BaseIcon,
            SectionTitleSmall,
            Step2DeliveryFormShippingOptions,
            Step2DeliveryFormTermsLabel,
            DataLoadingOverlay,
            Step2DeliveryFormMemberId
        },
        mixins: [
            Step2DeliveryFormValidationMixin
        ],
        props: {
            customerModel: {
                type: Object,
                required: true
            },
            shippingModel: {
                type: Object,
                required: true
            },
            agreeModel: {
                type: Object,
                required: true
            },
            hasHealthCheck: {
                type: Boolean,
                default: false
            },
            countries: {
                type: Array,
                default: () => ( [] )
            },
            userIsAuth: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                tooltip: false,
                states: [],
                isAutofillStates: false,
                isAutofillCountries: false
            };
        },
        computed: {
            ...mapGetters( 'states', {
                isLoadingStates: 'isLoading'
            } ),
            ...mapGetters ( 'appTenant', {
                isLoyaltyTypeNone: 'isLoyaltyTypeNone'
            } ),
            isPhoneRequired() {
                return !!this.customerModel.hasOwnProperty( 'phone' );
            },
            zipCodeErrors() {
                let commonErrors = [
                    ...this.requiredError( { model: 'customerModel', field: 'zip' } ),
                ];

                if ( this.customerModel.country && this.customerModel.country === 'CA' ){
                    return [
                        ...commonErrors,
                        ...this.zipError( { model: 'customerModel', field: 'zip' } )
                    ];
                }

                if ( this.customerModel.country && this.customerModel.country === 'US' ){
                    return [
                        ...commonErrors,
                        ...this.minLengthError( { model: 'customerModel', field: 'zip' } ),
                        ...this.numericError( { model: 'customerModel', field: 'zip' } ),
                    ];
                }

                return [];
            },
            hasShippingOptions() {
                return !!this.shippingModel.options.length;
            },
            isDisabledMemberId() {
                return this.userIsAuth && !!this.customerModel.memberId;
            },
            country: {
                get( ) {
                    return { code: this.customerModel.country }; 
                },
                set( value ) {
                    if ( value.code ) {
                        this.customerModel.country = value.code;
                    }
                }
            },
            state: {
                get( ) {
                    return { code: this.customerModel.state };
                },
                set( value ) {
                    if ( value.code ) {
                        this.customerModel.state = value.code;
                    } 
                }
            }
        },
        watch: {
            'customerModel.country': {
                handler: async function ( newValue, oldValue ) {
                    const isAutofill = !oldValue;
                    this.customerModel.state = isAutofill ? this.customerModel.state : '';

                    if ( newValue ) {
                        await this.loadStates( );
                        this.setState( this.customerModel.state );
                    } else {
                        this.clearStates( );
                    }
                },
                deep: true,
                immediate: true
            },
            '$v.agreeModel.healthCheck.$invalid': {
                handler: function( value ) {
                    this.$emit( 'healthcheck-validation-error', value );
                },
                deep: true
            },
        },
        methods: {
            validate( ) {
                this.$v.$touch();
                return !this.$v.$invalid;
            },
            clear( ) {
                this.$v.$reset();
            },
            stateFilters() {
                if ( this.customerModel.country ) {
                    return {
                        code: this.customerModel.country
                    };
                }
                return null;
            },
            clearStates( ) {
                this.options = [ ];
            },
            onBlurCountryDo() {
                this.$v.customerModel.zip.$touch( );
                this.formatZipCodeValue( );
            },
            onChangeCountryDo() {
                this.$v.customerModel.country.$touch( );
            },
            formatZipCodeValue() {
                if ( !this.$v.customerModel.zip.$invalid && this.customerModel.country ) {
                    this.customerModel.zip = formatUtilsZipFormat( this.customerModel.zip, this.customerModel.country );
                }
            },
            async loadStates() {
                const { success, data } = await this.$store.dispatch( `states/getRaw`, { filter: this.stateFilters() }  );
                if ( success ) {
                    this.states = [ ...data.list ];
                } else {
                    this.states = [ ];
                }
            },
            enableDropdownsAutofill() {
                const refs = [
                    this.$refs.states,
                    this.$refs.countries
                ];

                if ( refs ) {
                    refs.forEach( ( component ) => {
                        const input = component.$el.querySelector( 'input' );
                        input.removeAttribute( 'readonly' );
                        input.removeAttribute( 'autocomplete' );
                    } );
                }
            },
            setState( value ) {
                if ( this.states.length && !this.isLoadingStates && !this.$refs.states.isMenuActive ) {
                    const item = this.states.find( item => item.code === value || item.name === value );

                    this.customerModel.state = item ? item.code : '';
                    this.$refs.states.blur( );
                }
            },
        },
        mounted( ) {
            this.enableDropdownsAutofill( );
        }
    };
</script>
<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-checkout-step-2-form {
    .v-card {
        border-radius: border-radius( 'm' ) !important;
        border-color: theme-color( 'quinary' );
    }

    ::v-deep .v-icon__component {
        height: auto;
        width: auto;
    }

    .gst-input {
        ::v-deep .gst-icon-svg {
            ::v-deep .gst-svg-icon {
                fill: theme-color( 'tertiary' );
            }
        }
    }

    .gst-input.v-select--is-menu-active {
        ::v-deep .gst-icon-svg {
            transform: rotate( 180deg );

            ::v-deep .gst-svg-icon {
                fill: theme-color( 'primary' );
            }
        }
    }
}

.gst-info {
    font-size: font-size( 'xxs' );

    svg ::v-deep .gst-svg-icon {
        fill: theme-color( 'warning' );
    }

    span {
        line-height: 14px;
        padding-left: theme-spacing( 2 );
        max-width: 90%;
    }
}
</style>
