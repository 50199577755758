<template>
    <v-text-field
        :value="value"
        :data-test-id="testId"
        color="primary"
        class="gst-input--secondary gst-input"
        :label="label"
        :error-messages="errors"
        outlined
        :disabled="isDisabled"
        @input="$emit( 'input', $event )" />
</template>
<script>
    export default {
        name: 'Step2DeliveryFormMemberId',
        props: {
            value: {
                type: String,
                required: true
            },
            testId: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
            errors: {
                type: Array,
                default: () => ( [] )
            },
        },
    };
</script>
