<template>
    <div flat class="gst-step3-payment-split">
        <div
            class="gst-step3-payment-split__input pa-4">
            <div class="gst-step3-payment-split__input-wrapper mb-8 mb-md-2 d-flex">
                <div class="gst-step3-payment-split__input-item d-flex">
                    <transition name="fade" mode="out-in">
                        <DataLoadingOverlay
                            :absolute="false"
                            color="#FFFFFF"
                            opacity="0.6"
                            :show="!isPointsCorrelated && isPaymentWidgetLoaded"
                            class="gst-step3-payment-split__input-item--overlay">
                            <div
                                v-if="!editAmount"
                                key="1"
                                class="gst-step3-payment-split__label gst-step3-payment-split__label-readonly d-flex px-4">
                                {{ paymentAmount }}
                            </div>
                            <v-text-field
                                v-if="editAmount"
                                key="2"
                                ref="amountTextField"
                                v-model="amountValue"
                                min="0"
                                :max="maxAmount"
                                color="quinary"
                                single-line
                                outlined
                                hide-details
                                readonly
                                class="gst-input--secondary gst-step3-payment-split__textfield"
                                @keyup="enforceMinMax( $refs.amountTextField.$refs.input )"
                                @keyup.enter="toggleEditAmountValue"
                                @blur="toggleEditAmountValue"
                                @focus="setNumericInputType('amountTextField')" />
                        </DataLoadingOverlay>
                    </transition>
                    <div class="gst-step3-payment-split__tab-label d-flex">
                        {{ currency }}
                    </div>
                </div>
                <div :class="['px-5 gst-step3-payment-split__separator d-flex', { 'pt-4 pb-1': isMobile }]">
                    +
                </div>
                <div class="gst-step3-payment-split__input-item d-flex mb-2">
                    <transition name="fade" mode="out-in">
                        <div
                            v-if="!editPoints"
                            key="1"
                            class="gst-step3-payment-split__label d-flex px-4"
                            @click="toggleEditPointsValue"
                            @keypress.enter="toggleEditPointsValue">
                            {{ pointsFormatted }}
                        </div>
                        <v-text-field
                            v-if="editPoints"
                            ref="pointsTextField"
                            key="2"
                            v-model="value.points"
                            min="0"
                            :max="maxPoints"
                            color="primary"
                            single-line
                            outlined
                            :rules="[rules.minValue]"
                            class="gst-step3-payment-split__textfield"
                            @keyup="enforceMinMax( $refs.pointsTextField.$refs.input ) && isTabLabelFocused( $refs.pointsTextField.$refs.input ) && isTabLabelError( $refs.pointsTextField.$refs.input )"
                            @keyup.enter="toggleEditPointsValue"
                            @blur="toggleEditPointsValue"
                            @focus="setNumericInputType('pointsTextField')" />
                    </transition>
                    <div class="gst-step3-payment-split__tab-label d-flex"
                        :class="{
                            'gst-step3-payment-split__tab-label--focus': isFocusedState && editPoints,
                            'gst-step3-payment-split__tab-label--error': isErrorState && editPoints,
                        }">
                        {{ $t("_common:terms.bonusPoint_plural" ) }}
                    </div>
                </div>
            </div>
            <v-slider
                ref="slider"
                v-model="value.points"
                min="0"
                :max="maxPoints"
                color="primary"
                track-color="quinary"
                track-fill-color="primary"
                class="gst-step3-payment-split__slide u-mouse-pointer pt-md-2"
                hide-details
                @change="onSliderChange"
                @click="switchToReadRangeValue()">
                <template v-slot:thumb-label="{ value }">
                    <div :style="getThumbLabelVisibility(value)" class="gst-step3-payment-split__slide-thumb-label">
                        {{ $t( 'info.sliderMinimumRedeem', {count: minimumRedemptionPoints, formattedCount: minimumRedemptionPointsFormatted } ) }}
                    </div>
                </template>
            </v-slider>
            <div class="gst-step3-payment-split__slide-summary text-right text-lowercase d-flex float-end py-4">
                {{ $t("_common:terms.bonusPointWithCountApplied", {count: pointsValue, formattedCount: pointsFormatted } ) }} /
                <span class="primary--text xxs-text d-flex"> {{ $t("_common:terms.bonusPointWithCountAvailable", {count: userBonusPointsCurrent, formattedCount: userBonusPointsCurrentFormatted } ) }}
                    <BaseTooltip
                        :text="$t('tooltips.requiredPoints', {count: totalBonusPointsRedeem, formattedCount: totalBonusPointsRedeemFormatted } )"
                        :open-on-click="!$vuetify.breakpoint.mdAndUp"
                        :open-on-hover="$vuetify.breakpoint.mdAndUp"
                        class-content="gst-base-tooltip text-lowercase u-capitalize-first-letter u-text-transform-initial"
                        class-activator="pl-1">
                        <v-btn class="gst-checkout-step-3-form__tooltip-btn" icon small text :ripple="false">
                            <BaseIcon symbol-id="icons--info_round" class="gst-checkout-step-3-form__tooltip-icon" />
                        </v-btn>
                    </BaseTooltip>
                </span>
            </div>
            <div class="gst-step3-payment-split__info-redeem d-flex flex-row align-center u-alpha-background u-width-100">
                <BaseIcon symbol-id="icons--info_round" class="mr-1" />
                {{ $t('info.redeem') }}
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import apiServicePayments from '@core/api/apiServicePayments';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import currencyShort from '@core/filters/currencyShort';
    import LogService from '@core/services/LogService.js';
    import DataLoadingOverlay from '@core/shared/components/loading/DataLoadingOverlay.vue';

    export default {
        name: 'Step3PaymentSplit',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckoutWizard._components.step3Payment'
        },
        components: {
            BaseTooltip,
            BaseIcon,
            DataLoadingOverlay
        },
        emits: [
            'input',
            'update-validity'
        ],
        props: {
            value: {
                type: Object,
                required: true
            },
            bonusPointsRate: {
                type: Number,
                required: true,
            },
            maxPoints: {
                type: Number,
                required: true
            },
            maxAmount: {
                type: Number,
                required: true
            },
            currency: {
                type: String,
                default: ''
            },
            userBonusPointsCurrent: {
                type: Number,
                default: null,
            },
            minimumRedemptionPoints: {
                type: Number,
                default: null,
            },
            totalBonusPointsRedeem: {
                type: Number,
                required: true
            },
            isPaymentWidgetLoaded: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                editPoints: false,
                editAmount: false,
                rules: {
                    minValue: value => {
                        const isValid = value >= this.minimumRedemptionPoints;
                        this.isFocusedState = isValid;
                        this.isErrorState = !isValid;

                        return isValid || this.$t( 'info.sliderMinimumRedeem', { count: this.minimumRedemptionPoints, formattedCount: this.minimumRedemptionPointsFormatted } );
                    }
                },
                isFocusedState: false,
                isErrorState: false,
                pointsForAmountCalculation: null
            };
        },
        computed: {
            pointsValue( ) {
                return this.value.points;
            },
            amountValue: {
                get( ) {
                    return this.value.amount;
                },
                set( value ) {
                    this.$emit( 'input', { points: this.pointsValue, amount: value } );
                }
            },
            pointsFormatted( ) {
                return this.$options.filters.bonusPoints( this.pointsValue );
            },
            minimumRedemptionPointsFormatted() {
                return this.$options.filters.bonusPoints( this.minimumRedemptionPoints );
            },
            userBonusPointsCurrentFormatted( ) {
                return this.$options.filters.bonusPoints( this.userBonusPointsCurrent );
            },
            totalBonusPointsRedeemFormatted() {
                return this.$options.filters.bonusPoints( this.totalBonusPointsRedeem );
            },
            paymentAmount ( ) {
                return currencyShort( this.amountValue, this.currency );
            },
            isMobile() {
                return !this.$vuetify.breakpoint.mdAndUp;
            },
            totalAmount() {
                return this.value.total;
            },
            isPointsCorrelated() {
                return this.pointsForAmountCalculation === this.value.points && this.value.points > 0;
            },
            isFormValid( ) {
                return this.isPointsValid( this.value.points )
                    && this.isPointsCorrelated;
            }
        },
        watch: {
            'pointsValue': {
                handler: function ( value ) {
                    this.pointsValueDebounced( value );
                },
                immediate: true
            },
            'editPoints': {
                handler: function ( value ) {
                    if ( !value && !this.isPointsValid( this.value.points ) ) {
                        this.setMinimumValuePoints( );
                    }
                }
            },
            isFormValid: {
                handler: function( value ) {
                    this.$emit( 'update-validity', value );
                },
                immediate: true
            }
        },
        methods: {
            onSliderChange( value ) {
                if ( !this.isPointsValid( value ) ) {
                    this.setMinimumValuePoints( );
                }
            },
            pointsValueDebounced: debounce( function ( value ) {
                if ( this.isPointsValid( value ) ) {
                    this.handlePointsValueChanged( value );
                }
            }, 500 ),
            getThumbLabelVisibility( value ) {
                return {
                    display: value < this.minimumRedemptionPoints ? 'block' : 'none'
                };
            },
            switchToReadRangeValue( ) {
                this.editPoints = false;
                this.editAmount = false;
            },
            toggleEditPointsValue( ) {
                if ( !this.editPoints ) {
                    this.editPoints = true;
                    setTimeout( ( ) => {
                        this.$refs.pointsTextField.focus( );
                    }, 200 );
                } else {
                    this.editPoints = false;
                }
            },
            toggleEditAmountValue( ) {
                if ( !this.editAmount ) {
                    this.editAmount = true;
                    setTimeout( ( ) => {
                        this.$refs.amountTextField.focus( );
                    }, 200 );
                } else {
                    this.editAmount = false;
                }
            },
            enforceMinMax( el ) {
                if( el.value != '' ) {
                    if( parseInt( el.value ) < parseInt( el.min ) ) {
                        el.value = el.min;
                    }
                    if( parseInt( el.value ) > parseInt( el.max ) ){
                        el.value = el.max;
                    }
                }
            },
            setNumericInputType( refName ) {
                this.$nextTick( () => {
                    const input = this.$refs[refName].$el.querySelector( 'input' );

                    input.setAttribute( 'type', 'tel' );
                    input.setAttribute( 'inputmodel', 'numeric' );
                    input.setAttribute( 'pattern', '[0-9]*' );
                } );
            },
            async handlePointsValueChanged( points ) {
                try {
                    const api = await apiServicePayments( '' );
                    this.pointsForAmountCalculation = points;
                    const ret = await api.payments.calculateAmountRemainingFromTotal( this.totalAmount, points, this.totalBonusPointsRedeem );
                    if ( this.isPointsCorrelated ) {
                        this.$emit( 'input', { points: points, amount: ret.data.money } );
                    }
                } catch ( error ) {
                    LogService.error( 'Unable to calculate remaining amount' );
                }
            },
            setMinimumValuePoints( ) {
                this.$refs.slider.lazyValue = this.minimumRedemptionPoints;
                this.$emit( 'input', { points: this.minimumRedemptionPoints, amount: this.value.amount } );
                this.handlePointsValueChanged( this.minimumRedemptionPoints );
            },
            isPointsValid( points ) {
                return points >= this.minimumRedemptionPoints
                    && points <= this.maxPoints;
            },
        }
    };
</script>
<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-step3-payment-split {
        .gst-step3-payment-split__input {
            border: 1px solid theme-color( 'quinary' );
            justify-content: center;
        }

        .gst-step3-payment-split__input-wrapper {
            justify-content: space-evenly !important;
        }

        .gst-step3-payment-split__input-item {
            justify-content: center !important;

            .gst-step3-payment-split__input-item--overlay {
                height: 62px;
                border-radius: 10px 0 0 10px;
            }
        }

        .gst-step3-payment-split__label {
            height: theme-spacing( 15 );
            background-color: theme-color( 'white' );
            font-size: font-size( 'l' );
            font-weight: font-weight( 'medium' );
            letter-spacing: -0.47px;
            align-items: center;
            flex-grow: initial;
            border-radius: border-radius( 'm' ) 0 0 border-radius( 'm' ) !important;
            min-width: 155px;
            border-color: theme-color( 'septenary' );
            border-width: 1px;
            border-style: solid;
        }

        .gst-step3-payment-split__label-readonly {
            background-color: theme-color( 'quinary' );
        }

        .gst-step3-payment-split__textfield {
            height: 70px;
            border-radius: border-radius( 'm' ) 0 0 border-radius( 'm' ) !important;
            min-width: 155px;

            .v-input__control,
            .v-input__slot {
                min-height: 60px;
            }

            .v-text-field__details {
                padding: 0;

                .v-messages__message {
                    letter-spacing: -0.214px;
                    text-transform: lowercase;
                }

                .v-messages__message::first-letter {
                    text-transform: capitalize;
                }
            }
        }

        .gst-step3-payment-split__textfield.v-text-field--outlined.error--text fieldset {
            border: 1px solid theme-color( 'error' );
        }

        .gst-step3-payment-split__textfield.v-input--is-focused fieldset {
            border: 1px solid theme-color( 'primary' );
        }

        .gst-step3-payment-split__tab-label {
            height: theme-spacing( 15 );
            padding: 18px;
            border: 1px solid theme-color( 'septenary' );
            background-color: theme-color( 'quinary' );
            align-items: center;
            justify-content: center;
            border-radius: border-radius( 'none' ) border-radius( 'm' ) border-radius( 'm' ) border-radius( 'none' ) !important;
            border-left: 0 !important;
        }

        .gst-step3-payment-split__tab-label--focus {
            border: 1px solid theme-color( 'primary' );
        }

        .gst-step3-payment-split__tab-label--error {
            border: 1px solid theme-color( 'error' );
        }

        .gst-step3-payment-split__separator {
            font-size: font-size( 'l' );
            font-weight: font-weight( 'bold' );
            align-items: center !important;
            justify-content: center !important;
        }

        .gst-step3-payment-split__slide {
            .gst-step3-payment-split__slide-thumb-label {
                padding: theme-spacing( 2 );
                background-color: theme-color( 'tertiary' );
                color: theme-color( 'white' );
                font-size: 12px;
                white-space: nowrap;
                border-radius: 3px;
                transform: translateY( 190% );
                text-transform: lowercase;

                @include mobile-only {
                    transform: translateY( -20% ) translateX( 8% );
                }
            }

            .gst-step3-payment-split__slide-thumb-label::first-letter {
                text-transform: capitalize;
            }

            div.v-slider__thumb-label.primary {
                background-color: theme-color( 'transparent' ) !important;
            }

            .v-slider__thumb {
                left: -15px;
                cursor: pointer;
            }

            .v-slider--horizontal {
                margin-right: theme-spacing( 0 );
                margin-left: theme-spacing( 0 );
            }

            .v-slider__track-container {
                height: 6px !important;
            }

            .v-slider__thumb.primary {
                height: 28px !important;
                width: 28px !important;
                background: theme-color( 'white' ) !important;
                border-style: solid;
                border-width: 2px;
                box-shadow: 0 2px 4px 0 theme-color( 'shadow' );

                @include mobile-only {
                    height: 38px !important;
                    width: 38px !important;
                }
            }

            .v-slider__thumb.primary::before {
                display: none;
            }
        }

        .gst-step3-payment-split__slide-summary {
            font-size: font-size( 'xs' );
            letter-spacing: -0.412px;
            justify-content: flex-end;
            align-items: center;
        }

        .gst-step3-payment-split__info-redeem {
            position: relative;
            line-height: line-height( 's' );
            padding: theme-spacing( 1 );
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxs' );
            font-weight: font-weight( 'regular' );
            border-radius: border-radius( 'xs' ) !important;
            z-index: 0;

            svg {
                height: 18px;
                width: 18px;
            }

            svg > path {
                fill: theme-color( 'warning' ) !important;
            }

            &::after {
                background-color: theme-color( 'warning' );
                opacity: 0.1;
                border-radius: inherit;
            }
        }

        .gst-checkout-step-3-form__tooltip-icon {
            max-height: theme-spacing( 5 );
            max-width: theme-spacing( 5 );
        }

        .gst-checkout-step-3-form__tooltip-btn {
            max-height: theme-spacing( 5 );
            max-width: theme-spacing( 5 );

            svg > path {
                fill: theme-color( 'septenary' ) !important;
            }
        }

        .gst-checkout-step-3-form__tooltip-btn:hover {
            svg > path {
                fill: theme-color( 'primary' ) !important;
            }
        }
    }

    @include mobile-only {
        .gst-step3-payment-split {
            .gst-step3-payment-split__input-wrapper {
                flex-direction: column;
            }

            .gst-step3-payment-split__input-item {
                height: theme-spacing( 12 ) !important;

                .v-text-field {
                    max-width: 60% !important;
                }
            }

            .gst-step3-payment-split__label {
                width: 60%;
            }

            .gst-step3-payment-split__tab-label {
                width: 30%;
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.1s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
