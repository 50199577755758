<template>
    <DiscountBannerVue
        :discounts="discounts" />
</template>

<script>
    import { mapState } from 'vuex';
    import productsConstants from '@core/utils/constants/products';
    import DiscountBannerVue from '@tenants/fanatics/components/DiscountBanner.vue';

    const STORE_NAME = 'cart';

    export default {
        name: 'BannerInfo',
        components: {
            DiscountBannerVue
        },
        computed: {
            ...mapState( {
                cart:   state => state[STORE_NAME].current,
            } ),
            cartCollections( ) {
                return this.cart.collections;
            },
            tickets( ) {
                return this.cartCollections
                    .filter( item => item.productTypeId === productsConstants.TYPES.TICKET );
            },
            discounts( ) {
                return this.tickets[ 0 ].discounts;
            },
        }
    };
</script>