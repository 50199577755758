<template>
    <v-card flat class="d-flex flex-column gst-checkwiz-step-payment">
        <DataLoadingOverlay
            :show="shippingModel.loading || !isPaymentWidgetLoaded"
            :color="'white'">
            <PaymentProviderDownWarningContainer
                v-if="showPaymentProviderDownWarningContainer"
                class="gst-checkwiz-step-payment__payment-provider-down-warning-container"
                :button-label="$t( '_common:buttons.back' )"
                @close="$emit( 'back' )" />
            <template v-else>
                <v-card-text class="flex-grow-0 py-0 px-0">
                    <div v-show="hasSplitPayment" class="gst-split-payment">
                        <div class="gst-split-payment__title text-uppercase tertiary--text pa-2 font-weight-medium">
                            {{ $t( 'section.title' ) }}
                        </div>
                        <div class="gst-split-payment__wrapper row mb-5 mt-2">
                            <div class="col col-12 col-md-6 py-1 pr-md-1">
                                <BaseButton
                                    v-bind="$attrs"
                                    class="gst-split-payment__button"
                                    :class="{
                                        'gst-split-payment__button--active ': isCardPaymentMethod,
                                        'gst-split-payment__button--inactive': isSplitPaymentMethod,
                                        'u-mouse-default': isRedeemDisabled
                                    }"
                                    invert
                                    @click="togglePaymentType(paymentMethods.CARD)">
                                    <BaseIcon class="gst-icon-card-payment ml-1" :class="isCardPaymentMethod ? 'active' : 'inactive'" symbol-id="icons--card_outline" />
                                    {{ $t( '_common:buttons.cardPayment' ) }}
                                </BaseButton>
                            </div>
                            <div class="col col-12 col-md-6 py-1 pl-md-1">
                                <BaseTooltip
                                    open-on-click
                                    open-on-hover
                                    :class-content="isRedeemDisabled ? 'gst-base-tooltip gst-split-payment__tooltip' : 'd-none'"
                                    class-activator="d-flex align-center"
                                    :text="$t('tooltips.disableRedeem') ">
                                    <BaseButton
                                        class="gst-split-payment__button"
                                        v-bind="$attrs"
                                        :class="{
                                            'gst-split-payment__button--active': isSplitPaymentMethod,
                                            'gst-split-payment__button--inactive': isCardPaymentMethod,
                                            'gst-split-payment__button--disabled': isRedeemDisabled
                                        }"
                                        :disabled="isRedeemDisabled"
                                        invert
                                        @click="togglePaymentType(paymentMethods.SPLIT)">
                                        <BaseIcon class="gst-icon-checked mr-1" :class="isSplitPaymentMethod ? 'active' : 'inactive'" symbol-id="icons--checkboxes--round_checkbox_checked" />
                                        {{ $t( '_common:buttons.redeemPayment' ) }}
                                    </BaseButton>
                                </BaseTooltip>
                            </div>
                        </div>
                        <p v-if="annotationText" class="gst-split-payment__annotation">
                            {{ annotationText }}
                        </p>
                    </div>
                    <Step3PaymentSplit
                        v-if="isSplitPaymentMethod && isCountryUS"
                        v-model="paymentModelComputed"
                        :is-payment-widget-loaded="isPaymentWidgetLoaded"
                        :bonus-points-rate="bonusPointsRate"
                        :user-bonus-points-current="userBonusPointsCurrent"
                        :minimum-redemption-points="minimumRedemptionPoints"
                        :max-points="maxBonusPointsRedeemForSplit"
                        :max-amount="paymentModel.total"
                        :currency="currency"
                        :total-bonus-points-redeem="bonusPointsRedeem"
                        class="mb-4"
                        @update-validity="onStep3PaymentSplitValidityChange" />
                    <div v-show="paymentModel.paymentMethod && isPaymentWidgetLoaded">
                        <div>
                            <div v-if="!isSourceTmr" class="gst-checkwiz-step-payment__info-secure d-flex flex flex-row align-center u-alpha-background">
                                <BaseIcon symbol-id="icons--secure" class="gst-checkwiz-step-payment__icon-secure mr-2" />
                                {{ $t('info.security') }}
                            </div>
                            <div
                                v-show="paymentModel.amount"
                                class="gst-checkwiz-step-payment__info-card flex flex-row align-center mb-0 u-width-100"
                                :class="{
                                    'pt-0 pb-3': isSourceTmr,
                                    'py-3': !isSourceTmr
                                }">
                                {{ $t('info.card') }}
                            </div>
                        </div>
                        <PaymentWidget
                            v-show="paymentModel.amount"
                            ref="paymentWidget"
                            class="col col-12 py-0"
                            :token="paymentClientModel.token"
                            :type="paymentClientModel.providerId"
                            :payment-model="paymentModel"
                            :customer-model="customerModel"
                            :cart-id="cart.id"
                            @load-client-start="onLoadPaymentWidgetStartDo"
                            @load-client-success="onLoadPaymentWidgetSuccessDo"
                            @load-client-failed="onLoadPaymentWidgetFailedDo"
                            @open-authorization="$emit( 'open-authorization', $event)"
                            @close-authorization="$emit( 'close-authorization', $event)"
                            @update-payment-fields-validity="value => validProviderPaymentFields = value" />
                    </div>
                </v-card-text>
                <v-card-actions class="px-0">
                    <div class="d-flex flex flex-column flex-md-row justify-center">
                        <ButtonBack
                            v-if="$vuetify.breakpoint.mdAndUp"
                            :placeholder="$t( '_common:buttons.back' )"
                            @click="$emit( 'back' )" />
                        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
                        <ButtonNext
                            :placeholder="$t( '_common:buttons.placeOrder' )"
                            :width="!$vuetify.breakpoint.mdAndUp ? '100%' : ''"
                            :disabled="isDisabledNextButton"
                            @click="createPayment" />
                        <div v-if="!$vuetify.breakpoint.mdAndUp" xs12 class="gst-checkwiz-step-payment__total mt-4 d-flex flex-wrap flex-column">
                            {{ $t('total', { amount: $options.filters.currencyFull( paymentModel.amount, currency ) } ) }}
                            <div v-if="paymentModel.points">
                                + {{ $t('labels.redeem') }} {{ paymentModel.points }}
                            </div>
                            <v-btn
                                class="gst-btn--see-details text-none mt-2"
                                text
                                height="auto"
                                color="primary"
                                :ripple="false"
                                @click="openPaymentOrderDetailsModal">
                                {{ $t( 'buttons.seeDetails' ) }}
                            </v-btn>
                        </div>
                    </div>
                </v-card-actions>
            </template>
        </DataLoadingOverlay>
    </v-card>
</template>
<script>
    import Vue from 'vue';
    import { mapActions, mapState, mapGetters } from 'vuex';
    import paymentConstants from '@core/utils/constants/payment';
    import DataLoadingOverlay from '@core/shared/components/loading/DataLoadingOverlay.vue';
    import PaymentProviderDownWarningContainer from '@core/shared/components/notifications/PaymentProviderDownWarningContainer.vue';
    import ButtonBack from '@core/shared/components/wizard/step/ButtonBack.vue';
    import ButtonNext from '@core/shared/components/wizard/step/ButtonNext.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton.vue';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import PaymentWidget from '@core/shared/components/payment/PaymentWidget.vue';
    import LogService from '@core/services/LogService.js';
    import Step3PaymentSplit from './Step3PaymentSplit.vue';
    import Step3PaymentOrderDetailsModalContent from './Step3PaymentOrderDetailsModalContent.vue';

    export default {
        name: 'Step3Payment',
        components: {
            ButtonBack,
            BaseButton,
            ButtonNext,
            BaseIcon,
            Step3PaymentSplit,
            PaymentProviderDownWarningContainer,
            DataLoadingOverlay,
            BaseTooltip,
            PaymentWidget
        },
        emits: [
            'open-authorization',
            'close-authorization',
        ],
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckoutWizard._components.step3Payment'
        },
        props: {
            step: {
                type: Object,
                required: true
            },
            paymentModel: {
                type: Object,
                required: true
            },
            shippingModel: {
                type: Object,
                required: true
            },
            paymentClientModel: {
                type: Object,
                required: true
            },
            busEventsParent: {
                type: Object,
                required: true
            },
            cartCollection: {
                type: Object,
                required: true
            },
            order: {
                type: Object,
                required: true
            },
            bonusPointsRate: {
                type: Number,
                required: true
            },
            totalBonusPointsReddem: {
                type: Number,
                required: true
            },
            userBonusPointsCurrent: {
                type: Number,
                required: true
            },
            showSplitPayment: {
                type: Boolean,
                default: false
            },
            currentStep: {
                type: Number,
                required: true
            },
            busEventProgressStep: {
                type: Object,
                default: null
            },
            isSourceTmr: {
                type: Boolean,
                default: false
            },
            customerModel: {
                type: Object,
                required: true
            },
            cartId: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                showPaymentProviderDownWarningContainer: false,
                busEventOrderDetails: new Vue( ),
                isPaymentWidgetLoaded: false,
                loading: false,
                validProviderPaymentFields: false,
                isPointsValueInvalid: false
            };
        },
        computed: {
            ...mapGetters( {
                'isLoyaltyTypeAllowRedeem': 'appTenant/isLoyaltyTypeAllowRedeem',
                'isLoyaltyCurrencyTypeMoney': 'appTenant/isLoyaltyCurrencyTypeMoney',
                'minimumRedemptionPoints': 'appTenant/minimumRedemptionPoints',
                userIsAuth: 'user/isAuth',
            } ),
            ...mapState( {
                language: state => state.appState.language,
                cart:   state => state.cart.current
            } ),
            annotationText( ) {
                return this.$t( 'annotation' ) || null;
            },
            bonusPointsRedeem( ) {
                return this.totalBonusPointsReddem;
            },
            canBuyOnlyWithPoints( ) {
                return this.userBonusPointsCurrent >= this.bonusPointsRedeem;
            },
            paymentMethods( ) {
                return paymentConstants.METHODS;
            },
            paymentModelComputed: {
                get( ) {
                    return this.paymentModel;
                },
                set( value ) {
                    this.$emit( 'input', value );
                }
            },
            maxBonusPointsRedeemForSplit( ) {
                return Math.min( this.userBonusPointsCurrent, this.bonusPointsRedeem );
            },
            hasErrors( ) {
                return this.hasBonusPointsMaxError && !this.paymentModel.paymentMethod;
            },
            hasBonusPointsMaxError ( ) {
                return this.paymentModel.points > this.maxBonusPointsRedeem;
            },
            hasSplitPayment( ) {
                return this.isLoyaltyTypeAllowRedeem;
            },
            currency( ) {
                const { cartCollection } = this;
                return cartCollection.currency;
            },
            isDisabledNextButton( ) {
                const { paymentModel, validProviderPaymentFields } = this;

                return ( ( !paymentModel.paymentMethod || !validProviderPaymentFields ) && this.paymentModel.amount !== 0 ) || this.isPointsValueInvalid ;
            },
            isSplitPaymentMethod( ) {
                return this.paymentModel.paymentMethod === this.paymentMethods.SPLIT;
            },
            isCardPaymentMethod( ) {
                return this.paymentModel.paymentMethod === this.paymentMethods.CARD;
            },
            isRedeemDisabled( ) {
                const canDoRedemption = this.canDoSplitRedemption || this.canDoFullRedemption;
                const userReachedMinimumRedeem = this.userBonusPointsCurrent < this.minimumRedemptionPoints || this.bonusPointsRedeem < this.minimumRedemptionPoints;

                return !this.userIsAuth || !canDoRedemption || userReachedMinimumRedeem;
            },
            /**
             * This is used to hide slider on split payment when other events then US
             * TODO should be removed or update after split payment available also in other contries
             */
            isCountryUS() {
                const countryCodeUS = 'US';

                return this.cartCollection.info.venueCountryCode === countryCodeUS;
            },
            canDoFullRedemption( ) {
                return this.totalBonusPointsReddem <= this.userBonusPointsCurrent;
            },
            canDoSplitRedemption( ){
                return this.isCountryUS;
            }
        },
        watch: {
            'paymentModel.paymentMethod': function( value, oldValue ) {
                if ( value !== oldValue ) {
                    this.updatePaymentMethod( value );
                }
            },
            'paymentModel.total': function( ) {
                this.updatePaymentMethod( this.paymentModel.paymentMethod );
            },
            'paymentClientModel.token': {
                handler: async function ( value, oldValue ) {
                    if ( !value ) {
                        this.showPaymentProviderDownWarningContainer = true;
                    }
                    if ( value && value !== oldValue ) {
                        this.loading = true;
                        this.updatePaymentMethod( this.paymentModel.paymentMethod );
                        this.loading = false;
                    }
                },
                immediate: true
            },
            'currentStep': function( newValue ) {
                const { step, paymentModel, isSplitPaymentMethod } = this;
                if ( newValue === step.index && isSplitPaymentMethod ) {
                    this.updatePaymentMethod( paymentModel.paymentMethod );
                }
            }
        },
        methods: {
            ...mapActions( {
                notificationError: 'notification/error'
            } ),
            togglePaymentType( payment ) {
                if ( this.paymentModel.paymentMethod !== payment ) {
                    this.isPaymentWidgetLoaded = false;
                    this.paymentModel.paymentMethod = payment;
                }
            },
            createPayment( ) {
                this.$emit( 'next', {
                    isSplitPaymentMethod: this.isSplitPaymentMethod,
                    fnPayment: this.$refs.paymentWidget.submit,
                } );
            },
            hideClientToggle( ) {
                const sel = document.querySelector( '[data-braintree-id="toggle"]' );
                !sel || sel.click( );
            },
            onStep3PaymentSplitValidityChange( value ) {
                this.isPointsValueInvalid = !value;
            },
            async updatePaymentMethod( paymentMethod ) {
                switch ( paymentMethod ) {
                    case this.paymentMethods.POINTS:
                        this.$emit( 'input', { points: this.bonusPointsRedeem, amount: 0 } );
                        break;
                    case this.paymentMethods.CARD:
                        this.isPointsValueInvalid = false;
                        this.$emit( 'input', { points: 0, amount: this.paymentModel.total } );
                        break;
                    case this.paymentMethods.SPLIT: {
                        try {
                        this.isPointsValueInvalid = true;
                            this.$emit( 'input', {
                                points: Math.max( this.bonusPointsRedeem, this.userBonusPointsCurrent )
                            } );
                        } catch ( error ) {
                            LogService.error( 'Unable to calculate remaining amount' );
                        }
                        break;
                    }
                }
            },
            openPaymentOrderDetailsModal( ) {
                this.$modal.showBottom(
                    Step3PaymentOrderDetailsModalContent,
                    {
                        cartCollection: this.cartCollection,
                        order: this.order,
                        paymentModel: this.paymentModel,
                        shippingModel: this.shippingModel,
                        busEventsParent: this.busEventOrderDetails
                    },
                    {
                        'content-class': 'v-dialog__height-50 gst-v-dialog',
                        scrollable: true,
                        fullscreen: false,
                        'hide-overlay': false,
                        transition: 'dialog-bottom-transition'
                    }
                );
            },
            onLoadPaymentWidgetStartDo( ) {
                this.isPaymentWidgetLoaded = false;
                this.showPaymentProviderDownWarningContainer = false;
            },
            onLoadPaymentWidgetFailedDo( ) {
                this.showPaymentProviderDownWarningContainer = true;
            },
            onLoadPaymentWidgetSuccessDo( ) {
                this.isPaymentWidgetLoaded = true;
            },
        },
        async mounted( ) {
            !this.busEventsParent || this.busEventsParent.$on( 'save-error', this.hideClientToggle );
            !this.busEventOrderDetails || this.busEventOrderDetails.$on( 'next', this.createPayment );
        },
        destroyed( ) {
            !this.busEventsParent || this.busEventsParent.$off( 'save-error' );
            !this.busEventOrderDetails || this.busEventOrderDetails.$off( 'next' );
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-checkwiz-step-payment__payment-provider-down-warning-container {
        margin-top: 120px;
    }

    .gst-checkwiz-step-payment__info-secure {
        position: relative;
        line-height: 14px;
        padding: theme-spacing( 3, 2 );
        margin-bottom: theme-spacing( 0 );
        color: theme-color( 'success' );
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'regular' );
        border-radius: border-radius( 'm' ) !important;
        z-index: 0;

        &::after {
            background-color: theme-color( 'background-success' );
            opacity: 0.1;
            border-radius: inherit;
        }
    }

    .gst-checkwiz-step-payment__icon-secure {
        ::v-deep .gst-svg-icon {
            fill: theme-color( 'success' );
        }
    }

    .gst-checkwiz-step-payment__info-card {
        color: theme-color( 'quaternary' );
        font-size: font-size( 's' );
        font-weight: font-weight( 'medium' );
    }

    .gst-btn--see-details {
        line-height: line-height( 'l' );
        font-size: font-size( 's' );
        letter-spacing: -0.2px;
    }

    .gst-btn--see-details.v-btn::before {
        background-color: transparent;
    }

    .gst-split-payment {
        .gst-split-payment__title {
            font-size: font-size( 'xxs' );
            letter-spacing: 0.5px;
        }

        .gst-split-payment__annotation {
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxs' );
            font-weight: font-weight( 'regular' );
        }

        ::v-deep svg.gst-icon-card-payment.active path {
            fill: theme-color( 'primary' );
        }

        button.gst-split-payment__button {
            width: 100%;
        }

        button.gst-split-payment__button.v-btn--disabled.gst-btn.v-btn.gst-btn--disabled {
            background-color: theme-color( 'white' ) !important;
            color: theme-color( 'tertiary' ) !important;
            border-color: theme-color( 'septenary' ) !important;
            opacity: 0.4;

            ::v-deep .gst-icon-checked .gst-svg-icon {
                fill: theme-color( 'tertiary' );
            }
        }

        button.gst-split-payment__button.gst-split-payment__button--inactive {
            background-color: theme-color( 'white' ) !important;
            color: theme-color( 'tertiary' ) !important;
            border-color: theme-color( 'septenary' ) !important;

            ::v-deep svg.gst-icon-card-payment.inactive path {
                fill: theme-color( 'tertiary' ) !important;
            }

            ::v-deep .gst-icon-checked .gst-svg-icon {
                fill: theme-color( 'tertiary' );
            }

            ::v-deep .gst-icon-checked.gst-base-icon rect {
                fill: theme-color( 'tertiary' );
            }
        }

        .gst-base-icon {
            height: 24px;
        }

        .gst-btn.gst-btn--invert {
            background-color: theme-color( 'primary-lighten-1' ) !important;
        }
    }

    @include mobile-only {
        .gst-checkwiz-step-payment__payment-provider-down-warning-container {
            margin-top: theme-spacing( 15 );
        }
    }

</style>

<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .braintree-sheet {
        border: none;
    }

    .braintree-sheet__header {
        display: none;
    }

    .braintree-methods {
        display: none !important;
    }

    .braintree-placeholder {
        display: none;
    }

    .braintree-sheet__content--form {
        padding: theme-spacing( 0 );
    }

    .braintree-form__field-group {
        padding: 5px 10px !important;
        border-style: solid;
        border-width: 1px;
        border-color: theme-color( 'quinary' );
        border-radius: border-radius( 'xxs' );

        .braintree-form__label {
            color: theme-color( 'primary' ) !important;
        }
    }

    @media (min-width: 468px) {
        .braintree-form__field-group[data-braintree-id="expiration-date-field-group"] {
            margin-right: 5px !important;
        }
    }

    .braintree-form__hosted-field {
        padding-top: theme-spacing( 0 ) !important;
        padding-left: theme-spacing( 0 ) !important;
        border: none !important;
        margin: theme-spacing( 0 ) !important;
    }

    .gst-checkwiz-step-payment__total {
        width: 100%;
        text-align: center;
    }

    div.gst-base-tooltip.gst-split-payment__tooltip.v-tooltip__content {
        line-height: line-height( 's' );
        padding: theme-spacing( 2 );
        background: theme-color( 'tertiary' ) !important;
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'regular' );
        max-width: 184px;
    }
</style>
