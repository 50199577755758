<template>
    <div :key="token">
        <PaymentWidgetProcessOut
            v-if="type === providers.PROCESSOUT"
            ref="payment"
            :token="token"
            :cart-id="cartId"
            :customer-model="customerModel"
            :payment-model="paymentModel"
            @load-client-start="$emit( 'load-client-start', $event )"
            @load-client-failed="$emit( 'load-client-failed', $event )"
            @load-client-success="$emit( 'load-client-success', $event )"
            @open-authorization="$emit( 'open-authorization', $event)"
            @close-authorization="$emit( 'close-authorization', $event)"
            @update-payment-fields-validity="onUpdatePaymentFieldsValidityDo"
            v-on="$listeners" />
        <PaymentWidgetBraintree
            v-if="type === providers.BRAINTREE"
            ref="payment"
            :token="token"
            payment-client-container-id="paymentClientContainer"
            class="col col-12 py-0"
            @load-client-start="$emit( 'load-client-start', $event )"
            @load-client-failed="$emit( 'load-client-failed', $event )"
            @load-client-success="$emit( 'load-client-success', $event )"
            @open-authorization="$emit( 'open-authorization', $event)"
            @close-authorization="$emit( 'close-authorization', $event)"
            @update-payment-fields-validity="onUpdatePaymentFieldsValidityDo" />
    </div>
</template>

<script>
    import apiPaymentClientConstants from '@core/utils/constants/apiPaymentClient';
    import PaymentWidgetProcessOut from './PaymentWidgetProcessOut.vue';
    import PaymentWidgetBraintree from './PaymentWidgetBraintree.vue';

    export default {
        name: 'PaymentWidget',
        components: {
            PaymentWidgetProcessOut,
            PaymentWidgetBraintree
        },
        props: {
            token: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: false,
                default: apiPaymentClientConstants.PROVIDERS.BRAINTREE,
                validator: ( value ) => {
                    return Object.values( apiPaymentClientConstants.PROVIDERS ).includes( value );
                }
            },
            cartId: {
                type: String,
                default: ''
            },
            customerModel: {
                type: Object,
                required: true
            },
            /**
             * paymentModel
             * paymentModel.amount - number
             */
            paymentModel: {
                type: Object,
                required: true,
                validator: ( value ) => {
                    return value && typeof value.amount === 'number';
                }
            }
        },
        emits: [
            'update-payment-fields-validity',
            'load-client-start',
            'load-client-failed',
            'load-client-success',
            'open-authorization',
            'close-authorization'
        ],
        computed: {
            providers( ) {
                return apiPaymentClientConstants.PROVIDERS;
            },
        },
        methods: {
            /**
             * @description Submit the form
             * @param {String} invoiceId
             * @returns {Promise<void>}
             */
            submit( ) {
                return this.$refs.payment.submit( );
            },
            showErrors( ) {
                return this.$refs.payment.showErrors( );
            },
            init() {
                this.$refs.paymentWidget.init( );
            },
            onUpdatePaymentFieldsValidityDo( validPaymentFields ) {
                this.$emit( 'update-payment-fields-validity', validPaymentFields );
            }
        }
    };
</script>
